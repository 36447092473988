import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

// importing layout
import { Main } from "../layouts/main"

// Importing styles
import "./styles/post.scss"

// Importing Bootstrap components
import { Button, Col, Container, Row } from "react-bootstrap"

const Post = ({ data, pageContext }) => {
  const { wpPost } = data
  const { previous, next } = pageContext
  return (
    <Main>
      <div className="post-template">
        <Container>
          <Col className="post-intro" xs={12}>
            {wpPost.featuredImage ? (
              <GatsbyImage
                className="post-image"
                image={
                  wpPost.featuredImage.node.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={wpPost.featuredImage.node.altText}
              />
            ) : (
              <StaticImage
                className="post-image"
                src="../assets/images/logo.png"
                alt="Elevated Life placeholder image."
                placeholder="tracedSVG"
              />
            )}
            <div className="text">
              <span className="whisper">{wpPost.date}</span>
              <h1 className="shout">{wpPost.title}</h1>
              <p className="talk">Written by: {wpPost.author.node.name}</p>
            </div>
            <div className="separator" />
          </Col>
        </Container>
        <Container className="post-content">
          <div dangerouslySetInnerHTML={{ __html: wpPost.content }} />
        </Container>
        <Container className="post-pagination">
          <ul>
            <li>
              {previous && (
                <Link to={`/blog/${previous.slug}`} rel="prev">
                  <Button variant="accent">← Prev</Button>
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`/blog/${next.slug}`} rel="next">
                  <Button variant="accent">Next →</Button>
                </Link>
              )}
            </li>
          </ul>
        </Container>
      </div>
    </Main>
  )
}

export default Post

export const query = graphql`
  query WpPostById($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      __typename
      author {
        node {
          name
        }
      }
      content
      date(formatString: "MMMM Do, YYYY")
      excerpt
      featuredImage {
        node {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: AUTO)
            }
          }
          sourceUrl
        }
      }
      id
      seoFields {
        seo {
          description
          title
        }
      }
      title
    }
  }
`
